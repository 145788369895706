import React from 'react'

const SvgAdvantage02 = props => (
  <svg viewBox='0 0 58 58' {...props}>
    <defs>
      <style>{'.advantage-02_svg__cls-1{fill:none;stroke:#13cb75;stroke-width:2px}'}</style>
    </defs>
    <g id='advantage-02_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='advantage-02_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='advantage-02_svg__cls-1'
          d='M33.27 16.27H1V1h32.27zM24.73 41.73H57V57H24.73zM57 33.27H41.73V1H57zM1 24.73h15.27V57H1z'
        />
      </g>
    </g>
  </svg>
)

export default SvgAdvantage02
