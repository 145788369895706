import React from 'react'

const SvgAdvantage03 = props => (
  <svg viewBox='0 0 58 58' {...props}>
    <defs>
      <style>
        {
          '.advantage-03_svg__cls-1{fill:none;stroke:#13cb75;stroke-width:2px}.advantage-03_svg__cls-2{fill:#13cb75}'
        }
      </style>
    </defs>
    <g id='advantage-03_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='advantage-03_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path className='advantage-03_svg__cls-1' d='M1 1v12h56V1zM1 30h56V18H1zM1 47h56V35H1z' />
        <path className='advantage-03_svg__cls-2' d='M5 5h4v4H5zM5 22h4v4H5zM5 39h4v4H5z' />
        <path className='advantage-03_svg__cls-1' d='M29 47v10M0 57h58' />
      </g>
    </g>
  </svg>
)

export default SvgAdvantage03
