import React from 'react'

const SvgAdvantage05 = props => (
  <svg viewBox='0 0 58.6 58.22' {...props}>
    <defs>
      <style>{'.advantage-05_svg__cls-1{fill:none;stroke:#13cb75;stroke-width:2px}'}</style>
    </defs>
    <g id='advantage-05_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='advantage-05_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <circle className='advantage-05_svg__cls-1' cx={29.45} cy={28.86} r={19.3} />
        <path
          className='advantage-05_svg__cls-1'
          d='M29.34 16.55A12.31 12.31 0 1117 28.86a12.31 12.31 0 0112.34-12.31z'
        />
        <circle className='advantage-05_svg__cls-1' cx={29.34} cy={28.86} r={4.8} />
        <path
          className='advantage-05_svg__cls-1'
          d='M11.84 6a29.09 29.09 0 0140.39 40.35M49.43 40.94l2.27 5.71 6.59-2.18M46.87 52.24A29.09 29.09 0 016.49 11.86'
        />
        <path className='advantage-05_svg__cls-1' d='M9 17.19l-1.77-5.91-6.94 2.09' />
      </g>
    </g>
  </svg>
)

export default SvgAdvantage05
