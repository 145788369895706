import React from 'react'

const SvgProgram04 = props => (
  <svg viewBox='0 0 561.34 281' {...props}>
    <defs>
      <style>
        {
          '.program_04_svg__cls-1,.program_04_svg__cls-3{fill:none;stroke-miterlimit:10}.program_04_svg__cls-1{stroke-width:2px;stroke:#13cb75}.program_04_svg__cls-3{stroke:#666;stroke-dasharray:4 4}.program_04_svg__cls-4{fill:#666}.program_04_svg__cls-5{fill:#13cb75}'
        }
      </style>
    </defs>
    <g id='program_04_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='program_04_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <rect
          className='program_04_svg__cls-1'
          x={220.12}
          y={96.34}
          width={128.45}
          height={90.55}
          rx={2.01}
        />
        <path className='program_04_svg__cls-1' d='M275.12 186.89h18.11v13.75h-18.11z' />
        <path
          fill='#333'
          strokeMiterlimit={10}
          stroke='#13cb75'
          strokeWidth={2}
          d='M307.78 200.64h-46.96'
        />
        <rect
          className='program_04_svg__cls-3'
          x={0.5}
          y={0.5}
          width={140}
          height={280}
          rx={9.66}
        />
        <rect
          className='program_04_svg__cls-3'
          x={420.84}
          y={0.5}
          width={140}
          height={280}
          rx={9.66}
        />
        <path
          className='program_04_svg__cls-4'
          d='M159 142.45h-14M145 144.45h16.42M172.42 55.45H116v5l-8-6 8-6v5h56.42M215.42 144.45H116v5l-8-6 8-6v5h99.42M172.42 234.45H116v5l-8-6 8-6v5h56.42'
        />
        <path
          className='program_04_svg__cls-4'
          d='M171.71 53.16h2v181h-2zM410.88 142.45h14M424.88 144.45h-16.41M397.47 55.45h56.41v5l8-6-8-6v5h-56.41M354.47 144.45h99.41v5l8-6-8-6v5h-99.41M397.47 234.45h56.41v5l8-6-8-6v5h-56.41'
        />
        <path className='program_04_svg__cls-4' d='M396.18 53.16h2v181h-2z' />
        <path
          className='program_04_svg__cls-5'
          d='M37.32 205.16v42h25v5h-6v3h15v-3h-6v-5h23v-42zm3 39v-36h45v36z'
        />
        <path
          className='program_04_svg__cls-5'
          d='M44.32 217.16h16v3h-16zM44.32 225.16h23v3h-23zM44.32 234.16h38v3h-38zM37.32 119.66v42h25v5h-6v3h15v-3h-6v-5h23v-42zm3 39v-36h45v36z'
        />
        <path
          className='program_04_svg__cls-5'
          d='M44.32 131.66h16v3h-16zM44.32 139.66h23v3h-23zM44.32 148.66h38v3h-38zM37.32 34.16v42h25v5h-6v3h15v-3h-6v-5h23v-42zm3 39v-36h45v36z'
        />
        <path
          className='program_04_svg__cls-5'
          d='M44.32 46.16h16v3h-16zM44.32 54.16h23v3h-23zM44.32 63.16h38v3h-38z'
        />
        <path className='program_04_svg__cls-1' d='M477.39 53h6v32h-12V59a6 6 0 016-6z' />
        <path
          className='program_04_svg__cls-1'
          d='M507.39 53h12v32h-6a6 6 0 01-6-6V53z'
          transform='rotate(-180 513.39 69)'
        />
        <path
          className='program_04_svg__cls-1'
          d='M490.39 67h10v13a5 5 0 01-5 5 5 5 0 01-5-5V67z'
          transform='rotate(-180 495.39 76)'
        />
        <path className='program_04_svg__cls-1' d='M507.39 85h-24V40.16l12-6 12 6V85z' />
        <path className='program_04_svg__cls-5' d='M489.39 46h12v2h-12zM489.39 51h12v2h-12z' />
        <path className='program_04_svg__cls-1' d='M476.09 138.5h6v32h-12v-26a6 6 0 016-6z' />
        <path
          className='program_04_svg__cls-1'
          d='M506.09 138.5h12v32h-6a6 6 0 01-6-6v-26z'
          transform='rotate(-180 512.095 154.5)'
        />
        <path
          className='program_04_svg__cls-1'
          d='M489.09 152.5h10v13a5 5 0 01-5 5 5 5 0 01-5-5v-13z'
          transform='rotate(-180 494.095 161.5)'
        />
        <path className='program_04_svg__cls-1' d='M506.1 170.5h-24v-44.84l12-6 12 6v44.84z' />
        <path
          className='program_04_svg__cls-5'
          d='M488.09 131.5h12v2h-12zM488.09 136.5h12v2h-12z'
        />
        <path className='program_04_svg__cls-1' d='M477.39 223.16h6v32h-12v-26a6 6 0 016-6z' />
        <path
          className='program_04_svg__cls-1'
          d='M507.39 223.16h12v32h-6a6 6 0 01-6-6v-26z'
          transform='rotate(-180 513.39 239.165)'
        />
        <path
          className='program_04_svg__cls-1'
          d='M490.39 237.16h10v13a5 5 0 01-5 5 5 5 0 01-5-5v-13z'
          transform='rotate(-180 495.39 246.165)'
        />
        <path className='program_04_svg__cls-1' d='M507.39 255.16h-24v-44.83l12-6 12 6v44.83z' />
        <path
          className='program_04_svg__cls-5'
          d='M489.39 216.16h12v2h-12zM489.39 221.16h12v2h-12zM297.64 120.5h-30.73a4.34 4.34 0 100 8.67h30.73a4.34 4.34 0 100-8.67zm-18 5.33h-12v-2.66h12zm6.22.35a1.48 1.48 0 111.54-1.48 1.53 1.53 0 01-1.57 1.48zm5.49 0a1.48 1.48 0 111.54-1.48 1.48 1.48 0 01-1.57 1.48zm5.31 0a1.48 1.48 0 111.54-1.48 1.5 1.5 0 01-1.57 1.48zM297.64 130.5h-30.73a4.44 4.44 0 00-4.51 4.33 4.45 4.45 0 004.51 4.34h30.73a4.44 4.44 0 004.51-4.34 4.44 4.44 0 00-4.51-4.33zm-18 6h-12v-2.67h12zm6.31.3a1.48 1.48 0 111.54-1.48 1.48 1.48 0 01-1.57 1.48zm5.52 0a1.48 1.48 0 111.55-1.48 1.5 1.5 0 01-1.58 1.48zm5.32 0a1.48 1.48 0 111.54-1.48 1.5 1.5 0 01-1.57 1.48zM297.81 149.17a4.44 4.44 0 004.51-4.34 4.44 4.44 0 00-4.51-4.33h-30.73a4.45 4.45 0 00-4.52 4.33 4.45 4.45 0 004.52 4.34h14.53v4h-2v2.66h-16v2.67h16v2h6.66v-2h14.67v-2.67h-14.67v-2.66h-2v-4zm-1-6.16a1.48 1.48 0 11-1.54 1.48 1.51 1.51 0 011.57-1.49zm-5.31 0a1.48 1.48 0 11-1.54 1.48 1.5 1.5 0 011.57-1.49zm-5.48 0a1.48 1.48 0 11-1.55 1.48 1.46 1.46 0 011.58-1.49zm-6.44 2.82h-12v-2.66h12z'
        />
      </g>
    </g>
  </svg>
)

export default SvgProgram04
