import React from 'react'

const SvgProgram03 = props => (
  <svg viewBox='0 0 501.3 279.32' {...props}>
    <defs>
      <style>
        {
          '.program_03_svg__cls-1{fill:#666}.program_03_svg__cls-2,.program_03_svg__cls-4,.program_03_svg__cls-5,.program_03_svg__cls-6{fill:none;stroke-miterlimit:10}.program_03_svg__cls-2,.program_03_svg__cls-5,.program_03_svg__cls-6{stroke:#13cb75}.program_03_svg__cls-2,.program_03_svg__cls-4{stroke-width:2px}.program_03_svg__cls-3{fill:#333;opacity:.2}.program_03_svg__cls-4{stroke:#666}.program_03_svg__cls-6{stroke-dasharray:4.01 6.01}'
        }
      </style>
    </defs>
    <g id='program_03_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='program_03_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <circle className='program_03_svg__cls-1' cx={26.6} cy={129.11} r={5.49} />
        <path
          className='program_03_svg__cls-2'
          d='M245 229.87v33.82a14.63 14.63 0 01-14.63 14.63H15.63A14.63 14.63 0 011 263.69V125.46a14.63 14.63 0 0114.63-14.63h111.3'
        />
        <path
          className='program_03_svg__cls-3'
          d='M16.24 110.83h110.69v36.57H1v-21.33a15.24 15.24 0 0115.24-15.24z'
        />
        <circle className='program_03_svg__cls-1' cx={44.89} cy={129.11} r={5.49} />
        <circle className='program_03_svg__cls-1' cx={63.17} cy={129.11} r={5.49} />
        <path className='program_03_svg__cls-4' d='M21.11 148.15h91.19' />
        <path
          className='program_03_svg__cls-2'
          d='M256.32 55.4V15.74A14.74 14.74 0 01271.06 1h214.5a14.74 14.74 0 0114.74 14.74v138a14.74 14.74 0 01-14.74 14.75h-113.2'
        />
        <path
          className='program_03_svg__cls-3'
          d='M271.57 1h213.49a15.24 15.24 0 0115.24 15.24v21.33h-244V16.25A15.25 15.25 0 01271.57 1z'
        />
        <circle className='program_03_svg__cls-1' cx={293.26} cy={19.29} r={5.49} />
        <circle className='program_03_svg__cls-1' cx={311.55} cy={19.29} r={5.49} />
        <circle className='program_03_svg__cls-1' cx={329.83} cy={19.29} r={5.49} />
        <path className='program_03_svg__cls-4' d='M276.44 38.32h192.78' />
        <path
          className='program_03_svg__cls-2'
          d='M357.56 225.18h-214.5a14.74 14.74 0 01-14.74-14.74v-138a14.74 14.74 0 0114.74-14.74H368.9c8.14 0 3.4 6.6 3.4 14.74v138a14.74 14.74 0 01-14.74 14.74z'
        />
        <path
          className='program_03_svg__cls-3'
          d='M372.64 94.26h-244V72.83a15.14 15.14 0 0115.15-15.14h225.28c8.24 0 3.57 6.67 3.57 14.9z'
        />
        <circle className='program_03_svg__cls-1' cx={153.92} cy={75.97} r={5.49} />
        <circle className='program_03_svg__cls-1' cx={172.21} cy={75.97} r={5.49} />
        <circle className='program_03_svg__cls-1' cx={190.49} cy={75.97} r={5.49} />
        <path className='program_03_svg__cls-4' d='M148.43 93.93h192.79' />
        <path className='program_03_svg__cls-5' d='M273.44 271s.68 0 2 .05' />
        <path
          className='program_03_svg__cls-6'
          d='M281.44 271.08c29.79-.37 135.65-6.77 197.19-76.69'
        />
        <path
          className='program_03_svg__cls-5'
          d='M480.61 192.1c.43-.5.86-1 1.28-1.53M232.89 13.37h-2'
        />
        <path className='program_03_svg__cls-6' d='M224.88 13.32C195.1 13.69 89.24 20.1 27.69 90' />
        <path className='program_03_svg__cls-5' d='M25.72 92.3c-.43.5-.86 1-1.29 1.53' />
        <path
          d='M258.41 125.35c16.95 1.19 29.78 15.53 28.62 32.07a2 2 0 01-4.07-.24v-.05c1-14.26-10.11-26.68-24.83-27.71s-27.45 9.73-28.45 24 10.11 26.68 24.83 27.71a27.54 27.54 0 006.8-.36 2 2 0 11.72 4 31.25 31.25 0 01-7.8.41c-16.95-1.18-29.78-15.53-28.62-32.07s15.85-28.95 32.8-27.77zm3.09 32.39l24.43 8.68a1.48 1.48 0 01.92 1.93 1.53 1.53 0 01-.79.85l-9.3 4.13a3 3 0 00-1.59 1.57l-3.94 9.29a1.55 1.55 0 01-2 .82 1.58 1.58 0 01-.88-.91l-8.8-24.42a1.47 1.47 0 01.05-1.16 1.5 1.5 0 01.87-.77 1.21 1.21 0 011 0zm3.6 5.52l4.9 13.6 1.51-3.54a7 7 0 013.6-3.63h.05l3.55-1.58zm-8.78-23.06a15.08 15.08 0 0115.08 15.07 2 2 0 01-4.08 0 11 11 0 10-11.18 11h.18a2 2 0 110 4.08 15.08 15.08 0 110-30.15z'
          fill='#13cb75'
        />
      </g>
    </g>
  </svg>
)

export default SvgProgram03
