import styled, { css } from 'styled-components'
import { Media, typography, color, fadeIn, spacing } from '@renderbus/common/theme'
import { H3, H4, Flex } from '@renderbus/common/components'
import { lighten } from 'polished'

export const Section = styled.section`
  padding: 56px 0;
  background-color: #242424;
  .quality-list {
    justify-content: space-around;
    display: flex;
    padding-bottom: 74px;
  }
  ${Media.lessThan(Media.small)} {
    padding-bottom: ${p => (p.paddingMbBottom ? p.paddingMbBottom : '46px')};
    .quality-list {
      padding-bottom: 64px;
      flex-wrap: wrap;
    }
  }
`
const animationRule = css`
  animation: ${fadeIn} 2500ms;
`

export const LightSection = styled(Section)`
  background-color: #181818;
  padding-bottom: 117px;
`
export const BannerContainer = styled.div`
  position: relative;
  padding-top: 60px;
  height: 660px;
  color: white;
  h1 {
    margin: 0 auto;
    font-size: 72px;
    font-weight: bold;
    color: ${color.primary};
    span {
      color: white;
    }
  }
  .description {
    max-width: 555px;
    margin: 0 auto;
    margin-top: 24px;
    font-size: 20px;
    text-align: center;
  }
  .feature {
    margin: 0 auto;
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }
  .contact {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 50px;
    font-size: ${typography.h4};
  }
  ${Media.lessThan(Media.small)} {
    height: 375px;
    h1 {
      font-size: ${typography.title};
    }
    .description {
      margin-top: 5px;
      font-size: ${typography.h4};
      padding: 20px;
    }
    .contact {
      margin-top: 30px;
      font-size: ${typography.h4};
    }
    .feature {
      width: 65%;
      font-size: ${typography.text};
    }
    p {
      font-size: ${typography.text};
    }
  }
`
export const BannerContent = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 196px auto auto;
  align-items: flex-start;
  h1 {
    color: #fff;
    font-size: 40px;
    line-height: 1;
    width: 100%;
  }
  h2 {
    color: #bbb;
    font-size: 18px;
    line-height: 1;
    margin: 28px 0 40px;
  }
  button {
    color: #fff;
    background-color: ${color.primary};
    border: 0;
    :hover {
      color: #ffffff;
      background-color: rgb(66, 213, 145);
    }
  }
  .qrcode-wrap {
    margin-top: 16px;
    padding: 10px 40px;
    width: 280px;
    height: 356px;
    background: #444444;
    z-index: 1;
    .close-icon {
      :before,
      :after {
        float: right;
        position: relative;
        right: -20px;
        top: 2px;
        content: ' ';
        height: 14px;
        width: 1px;
        background-color: #ffffff;
        cursor: pointer;
      }
      :before {
        transform: rotate(45deg);
      }
      :after {
        transform: rotate(-45deg);
      }
    }
    img {
      width: 200px;
      height: 200px;
      margin-top: 14px;
      ${animationRule}
    }
    p {
      text-align: left;
      font-size: 16px;
      line-height: 1;
    }
  }
  ${Media.lessThan(Media.small)} {
    max-width: 242px;
    margin: 98px auto auto 26px;
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 12px;
      margin: 16px 0 22px;
      max-width: 216px;
      line-height: 18px;
    }
  }
`
export const SectionTitle = styled(H3)`
  font-size: 40px;
  text-align: center;
  margin-bottom: ${p => p.marginBottom};
  margin-top: ${p => p.marginTop};
  padding-top: ${p => p.paddingTop};
  ${Media.lessThan(Media.small)} {
    font-size: 20px;
    margin-bottom: ${p => (p.marginMbBottom ? p.marginMbBottom : '38px')};
    margin-top: 0;
    padding: 0 ${p => p.mbPaddingRight};
  }
`
export const SectionContentTitle = styled(H4)`
  font-size: 30px;
  text-align: left;
  line-height: 1;
  padding-bottom: 28px;
  ${Media.lessThan(Media.small)} {
    font-size: 18px;
    padding-bottom: 15px;
  }
`
export const ChoiceContainer = styled.div`
  height: 400px;
  min-width: 1400px;
  background: #242424;
  border-radius: 30px;
  margin: ${spacing.base} 0;
  display: flex;
  padding: 0 0 0 53px;
  flex-direction: ${p => (p.direction % 2 ? 'row-reverse' : 'row')};
  ${Media.lessThan(Media.small)} {
    height: auto;
    min-width: 100%;
    flex-direction: column;
    padding: 0 28px 40px;
  }
`
export const IntroduceContent = styled.div`
  margin: auto;
  width: 50%;
  flex-grow: 1;
  padding-left: 25px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    padding-left: 0;
    padding-top: 32px;
  }
`
export const IntroduceImage = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: auto;
  svg {
    width: ${p => p.svgWidth}px;
    margin-left: -40px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    svg {
      width: 90%;
      margin-left: 0;
    }
  }
`

export const SoftwareItem = styled(Flex)`
  width: 80px;
  flex-direction: column;
  margin: 0 25px;
  p {
    color: #999999;
    margin: 23px 0 26px;
    font-size: 14px;
  }
  ${Media.lessThan(Media.small)} {
    width: calc((100% - 160px) / 3);
    display: ${p => (p.isShow ? 'block' : 'none')};
    p {
      margin: 11px 0 13px;
      font-size: 7px;
      text-align: center;
    }
  }
`
export const SoftwareBG = styled(Flex)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${lighten(0.07, color.panel)};
  img {
    width: ${p => p.imgWidth}px;
  }
  ${Media.lessThan(Media.small)} {
    width: 45px;
    height: 45px;
    > img {
      transform: scale(0.5);
    }
  }
`
export const SoftwareImageList = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const SubTitle = styled.div`
  color: #999;
  text-align: left;
  font-size: 18px;
  width: 88%;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 37px;
    font-size: 12px;
    width: 100%;
  }
`
export const IntroWrapper = styled.div`
  margin: 0 auto;
  width: 1140px;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  p {
    width: 1060px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-top: 5px solid #13cb75;
    border-left: 5px solid #13cb75;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border-bottom: 5px solid #13cb75;
    border-right: 5px solid #13cb75;
  }
  ${Media.lessThan(Media.small)} {
    width: 92%;
    padding: 10px;
    p {
      width: 100%;
      font-size: 15px;
    }
    &::before {
      width: 20px;
      height: 20px;
      border-top: 2px solid #13cb75;
      border-left: 2px solid #13cb75;
    }
    &::after {
      width: 20px;
      height: 20px;
      border-bottom: 2px solid #13cb75;
      border-right: 2px solid #13cb75;
    }
  }
`
export const WebsiteCard = styled.div`
  padding: 0 10px 40px;
  font-size: 18px;
  display: flex;
  font-size: ${typography.h4};
  flex-direction: column;
  position: relative;
  .desc {
    color: #999999;
    margin: 40px 20px 30px;
    line-height: 30px;
  }
  .picDescription {
    color: #ffffff;
    font-size: 18px;
    position: absolute;
    top: 340px;
    left: 18px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: auto;
    padding: 0;
    .desc {
      margin: 22px 0 34px;
      height: auto;
      line-height: 18px;
      font-size: 12px;
      padding: 0 2px;
    }
    .picDescription {
      font-size: 12px;
      top: 164px;
      left: 13px;
    }
    img {
      width: 340px;
      height: 190px;
    }
  }
`
export const AdvantageContainer = styled.div`
  display: flex;
  margin-bottom: 58px;
  ${Media.lessThan(Media.small)} {
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 19px;
  }
`
export const AdvantageItem = styled.div`
  width: 25%;
  svg {
    width: 100%;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    margin: 0px;
    color: white;
    text-align: center;
  }
  ${Media.lessThan(Media.small)} {
    width: 33.3%;
    margin-bottom: 28px;
    svg {
      width: 100%;
      height: 30px;
      margin-bottom: 16px;
    }
    p {
      font-size: 14px;
    }
  }
`
export const Software = styled.div`
  img {
    display: block;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 38px;
  }
  p {
    margin: 0px;
    margin-bottom: 5px;
    color: white;
    text-align: center;
  }
  ${Media.lessThan(Media.small)} {
    width: 33.3%;
    img {
      margin-top: 48px;
      margin-bottom: 18px;
      height: 30px;
    }
  }
`
export const ServiceList = styled.div`
  display: flex;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
  }
`
export const RegisteredContainer = styled.div`
  padding: 40px 0 43px;
  text-align: center;
  background: ${color.primary};
  color: white;
  .qrcode-wrap {
    position: absolute;
    top: 136px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    margin: 0 auto;
    padding: 10px 40px;
    width: 280px;
    height: 356px;
    background: #444444;
    .close-icon {
      :before,
      :after {
        float: right;
        position: relative;
        right: -20px;
        top: 2px;
        content: ' ';
        height: 18px;
        width: 1px;
        background-color: #ffffff;
        cursor: pointer;
      }
      :before {
        transform: rotate(45deg);
      }
      :after {
        transform: rotate(-45deg);
      }
    }
    img {
      width: 200px;
      height: 200px;
      margin-top: 14px;
      ${animationRule}
    }
    p {
      text-align: left;
      font-size: 16px;
      line-height: 1;
    }
  }

  button {
    width: 200px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 11px 18px 0px rgba(12, 129, 75, 0.5);
    font-size: 18px;
    color: #14cc76;
  }
  ${Media.lessThan(Media.small)} {
    padding: 34px 0 43px;
  }
`
export const RegisteredTitle = styled.h2`
  margin: 1rem 1rem 32px;
  font-size: 1.875rem;
  font-weight: normal;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
    margin: 0 12px 14px;
  }
`
