import React from 'react'

const SvgProgram01 = props => (
  <svg viewBox='0 0 542 280.7' {...props}>
    <defs>
      <style>
        {
          '.program_01_svg__cls-2,.program_01_svg__cls-4,.program_01_svg__cls-6{fill:none}.program_01_svg__cls-2,.program_01_svg__cls-3,.program_01_svg__cls-4,.program_01_svg__cls-6,.program_01_svg__cls-7{stroke:#13cb75;stroke-width:2px}.program_01_svg__cls-2,.program_01_svg__cls-3,.program_01_svg__cls-4,.program_01_svg__cls-7{stroke-miterlimit:10}.program_01_svg__cls-3{fill:#202020}.program_01_svg__cls-4,.program_01_svg__cls-6{stroke-linecap:round}.program_01_svg__cls-6{stroke-linejoin:round}.program_01_svg__cls-10,.program_01_svg__cls-7{fill:#fff}.program_01_svg__cls-8{fill:#13cb75}.program_01_svg__cls-11{fill:#08c466}'
        }
      </style>
    </defs>
    <g id='program_01_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='program_01_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          d='M290.81 271.32c28.06.59 48.53 2.42 48.53 4.59 0 2.65-30.35 4.79-67.79 4.79s-67.79-2.14-67.79-4.79c0-2.19 20.81-4 49.22-4.6'
          fill='#282828'
        />
        <path
          className='program_01_svg__cls-2'
          d='M434.17 78.7a350.25 350.25 0 0176.42 67.14M297.45 31a296.08 296.08 0 0190 22.81M151.41 55.18a296.56 296.56 0 0194.29-24.26M35.44 141.11a349.43 349.43 0 0169.14-60.35'
        />
        <path
          className='program_01_svg__cls-3'
          d='M56.57 161.36a27.79 27.79 0 11-27.79-27.79 27.8 27.8 0 0127.79 27.79zM155.36 64.74A27.79 27.79 0 11127.57 37a27.8 27.8 0 0127.79 27.74zM299.11 28.79A27.79 27.79 0 11271.32 1a27.8 27.8 0 0127.79 27.79zM437.35 64.74A27.79 27.79 0 11409.56 37a27.8 27.8 0 0127.79 27.74zM539.08 161.36a27.79 27.79 0 11-27.79-27.79 27.8 27.8 0 0127.79 27.79z'
        />
        <path className='program_01_svg__cls-4' d='M347.72 186.2l2 .07' />
        <path
          d='M355.73 186.5C463.34 190.85 541 203.25 541 217.87c0 18.24-120.87 33-270 33s-270-14.78-270-33c0-14.88 80.44-27.46 191-31.59'
          strokeDasharray='4.01 6.02'
          strokeLinecap='round'
          strokeMiterlimit={10}
          stroke='#13cb75'
          strokeWidth={2}
          fill='none'
        />
        <path className='program_01_svg__cls-4' d='M195.09 186.17l2-.07' />
        <path
          className='program_01_svg__cls-6'
          d='M397.59 62.12h.28a11.36 11.36 0 0121.22-2.29 8.68 8.68 0 011.26-.1c6.08 0 10.62 6.57 6.89 13a7.2 7.2 0 01-6.24 3.49h-23.94a6.18 6.18 0 01-5.36-3c-3.19-5.47.69-11.1 5.89-11.1zM420.35 76.16a8.25 8.25 0 10-6.21-13.66M28.7 149.69l4.04-3.22 3.22 4.04M32.74 146.47a15.38 15.38 0 01-15.38 15.39M17.85 176.71v-7.56M25.41 176.71v-11.88M32.97 176.71v-15.12M40.53 176.71v-19.44'
        />
        <path
          className='program_01_svg__cls-2'
          d='M517.26 176.71H500a.56.56 0 01-.56-.57V147a.56.56 0 01.56-.56h22.63a.57.57 0 01.57.56v23.76a.56.56 0 01-.17.4l-5.42 5.35a.52.52 0 01-.35.2z'
        />
        <path className='program_01_svg__cls-2' d='M503.8 152.95h7.56v4.32h-7.56z' />
        <path
          className='program_01_svg__cls-7'
          d='M514.6 157.27h5.4M502.72 162.67H520M502.72 168.07H520'
        />
        <circle className='program_01_svg__cls-6' cx={124.76} cy={62.18} r={9.72} />
        <path
          className='program_01_svg__cls-6'
          d='M131.63 69.05l7.64 7.64M120.44 62.18h8.64M124.76 57.86v8.64'
        />
        <path
          className='program_01_svg__cls-8'
          d='M260.53 31v4.41c0 1.61 4.61 3.42 10.8 3.42s10.79-1.81 10.79-3.42V31c-2.11 1.64-6.55 2.51-10.79 2.51s-8.69-.91-10.8-2.51z'
        />
        <path
          className='program_01_svg__cls-8'
          d='M260.53 24.38v4.4c0 1.62 4.61 3.42 10.8 3.42s10.79-1.8 10.79-3.42v-4.4c-2.11 1.64-6.55 2.5-10.79 2.5s-8.69-.88-10.8-2.5z'
        />
        <path
          className='program_01_svg__cls-8'
          d='M260.53 17.8v4.4c0 1.61 4.61 3.42 10.8 3.42s10.79-1.81 10.79-3.42v-4.4c-2.11 1.64-6.55 2.5-10.79 2.5s-8.69-.86-10.8-2.5zM260.53 37.54V42c0 1.61 4.61 3.42 10.8 3.42s10.79-1.81 10.79-3.42v-4.4c-2.11 1.63-6.55 2.5-10.79 2.5s-8.69-.92-10.8-2.56z'
        />
        <path
          className='program_01_svg__cls-8'
          d='M271.33 12.2c-6.19 0-10.8 1.8-10.8 3.42s4.61 3.38 10.8 3.38 10.79-1.81 10.79-3.42-4.61-3.38-10.79-3.38z'
        />
        <rect
          className='program_01_svg__cls-2'
          x={202.79}
          y={126.54}
          width={137.52}
          height={90.55}
          rx={2.01}
        />
        <path className='program_01_svg__cls-2' d='M262.49 217.09h18.11v13.75h-18.11z' />
        <path
          fill='#333'
          strokeMiterlimit={10}
          stroke='#13cb75'
          strokeWidth={2}
          d='M295.03 230.84h-46.96'
        />
        <path
          className='program_01_svg__cls-10'
          d='M317.23 177l-.11.62a.51.51 0 00.51.62h3.71a1 1 0 011 1.23l-.44 2.48a1.53 1.53 0 01-1.45 1.23H314l.27-1.54h4.95a.78.78 0 00.73-.62l.11-.62a.51.51 0 00-.51-.62h-3.71a1 1 0 01-1-1.23l.44-2.48a1.54 1.54 0 011.45-1.24h6.5l-.28 1.55H318a.77.77 0 00-.72.62M299.05 174.81l.71-4h-2.16l-2.19 12.37h6.5a1.53 1.53 0 001.45-1.23l1-5.88a1 1 0 00-1-1.24zm-1.2 6.81l.93-5.26h2.78a.51.51 0 01.51.62l-.71 4a.77.77 0 01-.72.62zM313.9 174.81l-1.26 7.12a1.53 1.53 0 01-1.45 1.23h-5.26a1 1 0 01-1-1.23l1.23-7h2.16l-1.06 6a.51.51 0 00.51.62h2.16a.77.77 0 00.73-.62l1.09-6.19zM287.58 178.5l.42-2.5a1 1 0 00-1-1.24h-5.25a1.53 1.53 0 00-1.46 1.24l-1 5.87a1 1 0 001 1.24h5.88l.27-1.55h-4.33a.5.5 0 01-.51-.61l.22-1.24h4.33a1.53 1.53 0 001.46-1.24m-4.61-2.13h2.17a.5.5 0 01.51.62l-.11.61a.77.77 0 01-.73.62h-2.78l.21-1.23a.77.77 0 01.73-.62M251.12 174.81l-.28 1.55h-2.78a.78.78 0 00-.73.62l-1.09 6.18h-2.16l1.25-7.11a1.54 1.54 0 011.46-1.24zM269.45 176.05l-1.25 7.11H266l1.1-6.18a.51.51 0 00-.51-.62h-2.17a.78.78 0 00-.73.62l-1.09 6.18h-2.16l1.25-7.11a1.54 1.54 0 011.46-1.24h5.25a1 1 0 011 1.24M277.49 170.79l-.7 4h-4.33a1.54 1.54 0 00-1.46 1.24l-1 5.88a1 1 0 001 1.23h6.5l2.18-12.37zm-5.2 10.21l.71-4a.78.78 0 01.73-.62h2.78l-.92 5.26h-2.79a.51.51 0 01-.51-.62M259.74 178.53l.44-2.48a1 1 0 00-1-1.24h-5.28a1.55 1.55 0 00-1.46 1.24l-1 5.88a1 1 0 001 1.23H258l.28-1.54h-4a.5.5 0 01-.51-.62l.21-1.24h4.33a1.54 1.54 0 001.46-1.23m-4.57-2.17h2.17a.51.51 0 01.51.62l-.11.62a.78.78 0 01-.73.62h-2.78l.22-1.24a.77.77 0 01.72-.62M295.34 174.81l-.27 1.55h-2.79a.77.77 0 00-.72.62l-1.09 6.18h-2.17l1.26-7.11a1.54 1.54 0 011.45-1.24z'
        />
        <path
          id='program_01_svg__SVGID'
          className='program_01_svg__cls-11'
          d='M217.49 174.05a11.29 11.29 0 005.65 9.79v-9.79a5.65 5.65 0 115.65 5.65l4.65 4.65a11.3 11.3 0 10-15.95-10.3'
        />
        <path
          className='program_01_svg__cls-11'
          d='M261.46 164.74h-5.82l-.12.67h5.82l.12-.67zM257 167.65h4.34l.12-.66h-6.61l-.12.66h1.51l-1.98 3.39h6.46l-.52-2.68h-.76l.39 2.02h-4.42l1.59-2.73zM273.68 167.53h-.66l-.09.53h-2.91l-.12.66h2.91l-.42 2.38h.66l.42-2.38h3.04l.12-.66h-3.04l.09-.53z'
        />
        <path
          className='program_01_svg__cls-11'
          d='M270.62 169.75a1.1 1.1 0 01-1 .7l-.12.65a2 2 0 001.75-1.2l.39-.92H271zM275.43 169.75l-.11-.77h-.65l.07.92a1.28 1.28 0 001.33 1.2l.12-.65a.71.71 0 01-.76-.7zM272.04 164.86l-1.39-.37-.12.68 1.39.37.12-.68zM276.68 167a.53.53 0 01-.54-.66l.16-.92a.33.33 0 00-.33-.4h-1.54l.15-.53h-.65l-.15.53h-1.11l-.12.66h1l-.09.31a1.22 1.22 0 01-1.14.87l-.12.67a2 2 0 001.82-1.4l.13-.45h1.36l-.12.68a1.09 1.09 0 001.08 1.3h.15l.11-.66zM271.69 166.83l-1.5.28-.12.68 1.5-.28.12-.68zM271.85 165.91l-1.39-.37-.12.68 1.39.37.12-.68zM249.23 167.5h1.85l-.12.66h-1.85l-.51 2.91h.66l.4-2.25h.53l-.4 2.25h.66l.4-2.25h.53l-.4 2.25h.66l.4-2.25h.66l-.4 2.25h.7l.4-2.25v-.27a.33.33 0 00-.33-.39h-1.46l.12-.66h1.85l.12-.66h-4.36zM253.15 165.51h-1.06l.19-1.05h-.66l-.19 1.05h-1.06l.14-.79h-.66l-.26 1.46h4.1l.26-1.46h-.66l-.14.79zM248.06 168.16h.65l.12-.66h-.66l.37-2.12h.67l.11-.66h-1.98l-.12.66h.66l-.37 2.12h-.66l-.12.66h.67l-.4 2.25h-.65l-.12.66h1.99l.11-.66h-.67l.4-2.25zM268.33 167.13h-3.17a.5.5 0 00-.47.4l-.37 2.12a.32.32 0 00.33.39h3.17a.5.5 0 00.47-.39l.37-2.12a.33.33 0 00-.33-.4zm-.66 2.25H265l.09-.53h2.65zm.19-1.06h-2.65l.1-.52H268zM269.1 165h-1.56l-.21-.53h-.77l.21.53h-1.63a.5.5 0 00-.47.4l-.21 1.19h.66l.16-.93h3.44l-.16.93h.66l.21-1.19a.33.33 0 00-.33-.4zM263.67 171.1h4.76l.11-.66h-4.75l-.12.66z'
        />
        <path
          className='program_01_svg__cls-11'
          d='M265.61 166.08l-.11.66h2.64l.11-.66h-2.64zM263.08 168.28l-.09.51a1.47 1.47 0 01-.72 1.13l-.32.23-.16.95 1.29-1a1.2 1.2 0 00.46-.73l.19-1.12zM263.92 167.6l-.51-1.27h-.76l.51 1.27h.76zM264.24 165.72l-.52-1.27h-.76l.51 1.27h.77z'
        />
      </g>
    </g>
  </svg>
)

export default SvgProgram01
