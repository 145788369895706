import React from 'react'

const SvgAdvantage01 = props => (
  <svg viewBox='0 0 60 60' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M30 0A30 30 0 000 30a29.55 29.55 0 005.76 17.63A.9.9 0 006 48 30 30 0 1030 0zM9 34.68a35.66 35.66 0 00-3.06 8.82 27.49 27.49 0 01-2.71-20.2 40.82 40.82 0 005.23 6.76A4.16 4.16 0 008 31.94a4.26 4.26 0 001 2.74zm32.47-2.48a33.48 33.48 0 01-.4 5 4.26 4.26 0 00-3.33 3.1 38.39 38.39 0 01-21.5-6.76 4 4 0 00.34-1.62 4.29 4.29 0 00-1.06-2.82 33.89 33.89 0 0119.31-11.41 4.25 4.25 0 004.36 2.38 33.63 33.63 0 012.28 12.13zm.41 7.33A1.89 1.89 0 1140 41.41a1.88 1.88 0 011.88-1.88zm-3.2-21.83a1.88 1.88 0 111.88-1.88 1.89 1.89 0 01-1.88 1.88zM12.29 30.05a1.89 1.89 0 11-1.88 1.89 1.89 1.89 0 011.88-1.89zM11 36a4.26 4.26 0 003.72-.59 40.65 40.65 0 0022.58 7.28 39.44 39.44 0 01-29.39 3.5A33.43 33.43 0 0111 36zm33.19 1.81a4.42 4.42 0 00-.75-.39 35.61 35.61 0 00.41-5.25 35.86 35.86 0 00-2.48-13.08 4.26 4.26 0 001.38-2.05 34.41 34.41 0 0112.85 3.11 39.06 39.06 0 01-11.39 17.69zm-1.4-23.17a4.27 4.27 0 00-5.34-2.95 35.85 35.85 0 00-9.15-9.27A27.78 27.78 0 0154.35 17a37.24 37.24 0 00-11.54-2.33zm-8.38.65a36.3 36.3 0 00-21 12.51 4.09 4.09 0 00-3.39.45 38.41 38.41 0 01-5.86-8.09A27.76 27.76 0 0124.6 2.94 33.88 33.88 0 0135.45 13a4.23 4.23 0 00-1.02 2.32zM39 44.55l.1.08a33.91 33.91 0 01-9 12.93 27.59 27.59 0 01-19.77-8.4A41.69 41.69 0 0039 44.55zm-5.3 12.8a36.09 36.09 0 007.55-11.7 4.7 4.7 0 00.63.05 4.26 4.26 0 004.25-3.82 41.14 41.14 0 0010.13-3.42A27.81 27.81 0 0133.7 57.35zM57.6 30a27.68 27.68 0 01-.52 5.3A38.46 38.46 0 0146 39.47a41.56 41.56 0 0010.88-15.71A27.34 27.34 0 0157.6 30z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgAdvantage01
