import React from 'react'

const SvgAdvantage04 = props => (
  <svg viewBox='0 0 58 63.6' {...props}>
    <defs>
      <style>{'.advantage-04_svg__cls-2{fill:#13cb75}'}</style>
    </defs>
    <g id='advantage-04_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='advantage-04_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          d='M14.74 24.23l15.18 8.24 13.34-8.73L29 16z'
          strokeMiterlimit={10}
          fill='none'
          stroke='#13cb75'
          strokeWidth={2}
        />
        <path
          className='advantage-04_svg__cls-2'
          d='M43.27 29.47L29.94 38.2l-.01-.01v.01l-13.09-7.1.01-.01-2.07-1.12-2.04 1.18 17.28 9.37 15.24-9.97-1.99-1.08z'
        />
        <path
          className='advantage-04_svg__cls-2'
          d='M43.27 36.53l-13.33 8.72h-.01l-13.09-7.09.01-.01-2.07-1.12-2.04 1.17 17.28 9.38 15.24-9.97-1.99-1.08z'
        />
        <path
          d='M52.52 13L33.62 2.12a10.09 10.09 0 00-9.24 0L5.48 13A9.76 9.76 0 001 20.88v21.84a10 10 0 004.48 7.84l18.9 10.92A9.8 9.8 0 0029 62.6a10.23 10.23 0 004.62-1.12l18.9-10.92A10 10 0 0057 42.72V20.88A9.76 9.76 0 0052.52 13z'
          fill='none'
          stroke='#13cb75'
          strokeWidth={2}
        />
      </g>
    </g>
  </svg>
)

export default SvgAdvantage04
